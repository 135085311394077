import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import cuposBg from './img/cupos-bg.png'
import closeIcon from './img/close-icon.png';
import { cuposFormUrl } from './config'

let timeout: NodeJS.Timeout

const Overlay = styled.div`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
`

const Close = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 8px;
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  top: 16px;
  left: 16px;

  img {
    filter: invert(1);
    width: 16px;
    height: 16px;
  }
`;

const ModalContainer = styled.div<{
  open: boolean,
}>`
  ${props => props.open ? 'display: flex;' : 'display: none;'}
`

const Modal = styled.div<{
  bg: string
}>`
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 200px;
  background-color: #fff;
  border-radius: 24px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
  width: 600px;
  height: 500px;

  .btn {
    position: absolute;
    right: 24px;
    bottom: 120px;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 320px;

    .btn {
      bottom: 60px;
    }
  }
`

const ModalCupos = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setOpen(true)
    }, 1000)
  }, [])
  

  return (
    <ModalContainer open={open}>
      <Overlay onClick={() => setOpen(false)} />
      <Modal bg={cuposBg}>
        <Close onClick={() => setOpen(false)}>
          <img src={closeIcon} alt="close" />
        </Close>
        <a
          className="btn big"
          href={cuposFormUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Solicitar
        </a>
      </Modal>
    </ModalContainer>
  )
}

export default ModalCupos